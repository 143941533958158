
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

* {
  box-sizing: border-box;
}

a {
  color: #aa8e6c !important;
}

a:visited {
  color: #aa8e6c;
}

a:hover {
  color: #b7a99a !important;
}

.alice-carousel__stage {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.alice-carousel__stage-item {
  display: inline-flex !important;
  flex-grow: 1;
  flex-shrink: 0;
  height: unset !important;
}

.alice-carousel__stage-item > div > a {
  display: flex;
  height: 100%;
  width: 100%;
}

.alice-carousel__stage-item > div > a > div {
  width: 100%;
  height: 100%;
}

.ant-select-selector {
  min-height: 56px !important;
}
