@import "~react-toastify/dist/ReactToastify.min.css";

.Toastify__toast-container--top-right {
  margin-top: 72px;
  top: 0;
}

.Toastify__toast {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  min-height: 45px;
  margin-bottom: 5px;
  justify-items: center;
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__progress-bar {
  height: 2px;
  background-color: #fff;
}

.Toastify__toast--success {
  background-color: #4caf50;
  color: #fff;
  font-weight: 500;
}

.Toastify__toast--error {
  background-color: #f44336;
  color: #fff;
  font-weight: 500;
}

.Toastify__toast--success:before {
  font-family: "Material Icons";
  content: "check_circle_outline";
  font-size: 1.3rem;
  margin-right: 10px;
  padding-top: 2px;
}

.Toastify__toast--error:before {
  font-family: "Material Icons";
  content: "error_outline";
  font-size: 1.3rem;
  margin-right: 10px;
  padding-top: 2px;
}
